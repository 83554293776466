import { Fragment } from 'react';
import { Navbar, Button, Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react';
import { FunctionComponent } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const solutions = [
  {
    name: 'A propos',
    href: 'https://compagnons-du-devoir.com/notre-association/a-propos/',
  },
  {
    name: 'Le collège des métiers',
    href: 'https://compagnons-du-devoir.com/notre-association/le-college-des-metiers/',
  },
  {
    name: "L'accueil et l'accompagnement",
    href: 'https://compagnons-du-devoir.com/laccueil-et-laccompagnement/',
  },
  {
    name: 'Les délégations régionales',
    href: 'https://compagnons-du-devoir.com/notre-association/les-delegations-regionales/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const noussoutenir = [
  {
    name: 'Pourquoi nous soutenir ?',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/pourquoi-nous-soutenir/',
  },
  {
    name: 'Faire un don',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/faire-un-don/',
  },
  {
    name: 'Mécénat et partenariat',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/mecenat-et-partenariat/',
  },
  {
    name: 'Legs, donation, assurance vie',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/legs-donation-assurance-vie/',
  },
  {
    name: 'Les projets à soutenir',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const espaceentreprises = [
  {
    name: 'Recruter un(e) alternant(e)',
    href: 'https://compagnons-du-devoir.com/espace-entreprises/accueillir-un-jeune/',
  },
  {
    name: 'Former ses salariés',
    href: 'https://compagnons-du-devoir.com/espace-entreprises/former-ses-salaries/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const espacecompagnons = [
  {
    name: 'Cotiser',
    href: 'https://compagnons-du-devoir.com/espace-compagnons/cotiser/',
  },
  {
    name: 'Entreprendre',
    href: 'https://compagnons-du-devoir.com/espace-compagnons/entreprendre/',
  },
  {
    name: 'Les assises du compagnonnage',
    href: 'https://compagnons-du-devoir.com/espace-compagnons/les-assises/',
  },
];

/**
 * Ce composant affiche le menu du haut de la partie Front.
 */
export const TopBar: FunctionComponent = () => {
  /**
   * Affiche le menu navigable.
   */
  const navList = (
    <ul id='MainMenuList' className='mb-4 mt-2 flex flex-col lg:mb-0 lg:mt-0 lg:flex-row lg:items-center gap-2 xl:gap-2 2xl:gap-4'>
      <li>
        <Menu allowHover={true}>
          <MenuHandler>
            <Button
              variant='text'
              ripple={false}
              className='text-white font-normal flex items-center hover:bg-transparent active:bg-transparent focus:outline-none px-0 poppins'>
              L'association
              <ChevronDownIcon className='text-white ml-0 xl:ml-2 h-3 w-3 group-hover:text-primary' aria-hidden='true' />
            </Button>
          </MenuHandler>
          <MenuList className='bg-secondary-2 border-none rounded-t-none p-0 overflow-hidden'>
            {solutions.map((item) => (
              <MenuItem key={item.name} className='!bg-transparent hover:!bg-secondary-1 rounded-none'>
                <a href={item.href} className='-m-3 flex items-start rounded-none p-4 text-white hover:text-primary text-xs poppins'>
                  {item.name}
                </a>
              </MenuItem>
            ))}
            <a
              href='https://compagnons-du-devoir.com/nos-actualites/'
              className='block text-white m-3 bg-primary rounded-lg p-3 hover:bg-secondary-1 text-xs poppins'>
              Nos actualités
            </a>
          </MenuList>
        </Menu>
      </li>
      <li>
        <a href='https://compagnons-du-devoir.com/nos-maisons-et-cfa/' className=' uppercase text-[12px] poppins'>
          Maisons et CFA
        </a>
      </li>
      <li>
        <Menu allowHover={true}>
          <MenuHandler>
            <Button variant='text' ripple={false} className=' text-white font-normal flex items-center hover:bg-transparent active:bg-transparent px-0 poppins'>
              Nous soutenir
              <ChevronDownIcon className='text-white ml-0 xl:ml-2 h-3 w-3 group-hover:text-primary' aria-hidden='true' strokeWidth={7} />
            </Button>
          </MenuHandler>
          <MenuList className='bg-secondary-2 border-none rounded-t-none p-0 overflow-hidden'>
            {noussoutenir.map((item) => (
              <MenuItem key={item.name} className='!bg-transparent hover:!bg-secondary-1 rounded-none'>
                <a href={item.href} className='-m-3 flex items-start rounded-none p-4 text-white hover:text-primary text-xs poppins'>
                  {item.name}
                </a>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </li>
      <li>
        <Menu allowHover={true}>
          <MenuHandler>
            <Button variant='text' ripple={false} className=' text-white font-normal flex items-center hover:bg-transparent active:bg-transparent px-0 poppins'>
              Espace entreprises
              <ChevronDownIcon className='text-white ml-0 xl:ml-2 h-3 w-3 group-hover:text-primary' aria-hidden='true' />
            </Button>
          </MenuHandler>
          <MenuList className='bg-secondary-2 border-none rounded-t-none p-0 overflow-hidden'>
            {espaceentreprises.map((item) => (
              <MenuItem key={item.name} className='!bg-transparent hover:!bg-secondary-1 rounded-none'>
                <a href={item.href} className='-m-3 flex items-start rounded-none p-4 text-white hover:text-primary text-xs poppins'>
                  {item.name}
                </a>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </li>
      <li>
        <Menu allowHover={true}>
          <MenuHandler>
            <Button
              variant='text'
              ripple={false}
              className=' text-white font-normal flex flex-row items-center hover:bg-transparent active:bg-transparent px-0 poppins'>
              <span>Espaces Compagnons</span>
              <ChevronDownIcon className='text-white ml-0 xl:ml-2 h-3 w-3 group-hover:text-primary' aria-hidden='true' />
            </Button>
          </MenuHandler>
          <MenuList className='bg-secondary-2 border-none rounded-t-none p-0 overflow-hidden'>
            {espacecompagnons.map((item) => (
              <MenuItem key={item.name} className='!bg-transparent hover:!bg-secondary-1 rounded-none'>
                <a href={item.href} className='-m-3 flex items-start rounded-none p-4 text-white hover:text-primary text-xs poppins'>
                  {item.name}
                </a>
              </MenuItem>
            ))}
            <a href='https://laruche.compagnons-du-devoir.com' className=' block text-white m-3 bg-primary rounded-lg p-3 hover:bg-secondary-1 text-xs poppins'>
              La ruche
            </a>
          </MenuList>
        </Menu>
      </li>
      <li>
        <a href='https://www.compagnons-du-devoir.com/nous-contacter' className=' flex items-center text-xs uppercase poppins'>
          Nous contacter
        </a>
      </li>
    </ul>
  );

  return (
    <Fragment>
      {/* Navbar pour desktop */}
      <Navbar className='hidden lg:flex lg:flex-col sticky top-0 z-50 px-7 xl:px-5 2xl:px-11 py-2 w-full h-full max-w-full bg-transparent lg:bg-secondary-2 rounded-none backdrop-blur-0 border-none'>
        <div className='relative flex items-center justify-between text-white'>
          {/* Logo visible uniquement sur desktop */}
          <Link to='/' className='hidden lg:flex items-center gap-3'>
            <img className='h-8' src='/assets/logo-aocdtf.webp' alt='Compagnons du devoir et du Tour de France' />
            <span className='text-white leading-3 text-[10px]'>
              Les Compagnons du Devoir
              <br /> et du Tour de France
            </span>
          </Link>

          {/* Barre de navigation visible uniquement sur desktop */}
          <div className='hidden lg:flex items-center'>
            <div>{navList}</div>
            <Button
              variant='outlined'
              size='sm'
              ripple={false}
              className='ml-4 rounded-full px-5 md:px-2 xl:px-5 py-1 text-sm font-normal leading-6 border-white text-white shadow-sm bg-transparent hover:border-primary hover:bg-primary hover:text-white hover:opacity-100 focus:ring-0'
              style={{ fontFamily: 'Arial', textTransform: 'none' }}>
              Je fais un don
            </Button>
          </div>
        </div>
      </Navbar>
    </Fragment>
  );
};
