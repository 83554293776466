/* This example requires Tailwind CSS v3.0+ */
import { Typography, Button, Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react';
import { FunctionComponent, useState, useEffect } from 'react';
import { ArrowRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';

import ReactDOM from 'react-dom';
/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const seformer = [
  {
    name: 'Parcours Tour de France (moins de 25 ans)',
    href: 'https://compagnons-du-devoir.com/se-former/parcours-tour-de-france-moins-de-25-ans/',
  },
  {
    name: 'Formation continue (25 ans et plus)',
    href: 'https://compagnons-du-devoir.com/se-former/formation-continue-25-ans-et-plus/',
  },
  {
    name: 'Devenir Compagnon du Devoir',
    href: 'https://compagnons-du-devoir.com/se-former/devenir-compagnon-du-devoir/',
  },
  {
    name: 'Foire aux questions',
    href: 'https://compagnons-du-devoir.com/foire-aux-questions/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const metiers = [
  {
    name: 'Bâtiment & aménagement',
    href: 'https://compagnons-du-devoir.com/metiers/batiment-amenagement/',
  },
  {
    name: "Technologies de l'industrie",
    href: 'https://compagnons-du-devoir.com/metiers/technologies-de-lindustrie/',
  },
  {
    name: 'Métier du goût',
    href: 'https://compagnons-du-devoir.com/metiers/metiers-du-gout/',
  },
  {
    name: 'Matériaux souples',
    href: 'https://compagnons-du-devoir.com/metiers/materiaux-souples/',
  },
  {
    name: 'Et aussi...',
    href: 'https://compagnons-du-devoir.com/metiers/et-aussi/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const serencontrer = [
  {
    name: 'Journées ports ouvertes',
    href: 'https://compagnons-du-devoir.com/se-rencontrer/jsf/jet-engine:listingevents/tax/type-devenement:237/',
  },
  {
    name: "Les salons d'orientation",
    href: 'https://compagnons-du-devoir.com/se-rencontrer/jsf/jet-engine:listingevents/tax/type-devenement:238/',
  },
  {
    name: 'Les salons professionnels',
    href: 'https://compagnons-du-devoir.com/se-rencontrer/jsf/jet-engine:listingevents/tax/type-devenement:239/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const solutions = [
  {
    name: 'A propos',
    href: 'https://compagnons-du-devoir.com/notre-association/a-propos/',
  },
  {
    name: 'Le collège des métiers',
    href: 'https://compagnons-du-devoir.com/notre-association/le-college-des-metiers/',
  },
  {
    name: "L'accueil et l'accompagnement",
    href: 'https://compagnons-du-devoir.com/laccueil-et-laccompagnement/',
  },
  {
    name: 'Les délégations régionales',
    href: 'https://compagnons-du-devoir.com/notre-association/les-delegations-regionales/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const noussoutenir = [
  {
    name: 'Pourquoi nous soutenir ?',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/pourquoi-nous-soutenir/',
  },
  {
    name: 'Faire un don',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/faire-un-don/',
  },
  {
    name: 'Mécénat et partenariat',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/mecenat-et-partenariat/',
  },
  {
    name: 'Legs, donation, assurance vie',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/legs-donation-assurance-vie/',
  },
  {
    name: 'Les projets à soutenir',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const espaceentreprises = [
  {
    name: 'Recruter un(e) alternant(e)',
    href: 'https://compagnons-du-devoir.com/espace-entreprises/accueillir-un-jeune/',
  },
  {
    name: 'Former ses salariés',
    href: 'https://compagnons-du-devoir.com/espace-entreprises/former-ses-salaries/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const espacecompagnons = [
  {
    name: 'Cotiser',
    href: 'https://compagnons-du-devoir.com/espace-compagnons/cotiser/',
  },
  {
    name: 'Entreprendre',
    href: 'https://compagnons-du-devoir.com/espace-compagnons/entreprendre/',
  },
  {
    name: 'Les assises du compagnonnage',
    href: 'https://compagnons-du-devoir.com/espace-compagnons/les-assises/',
  },
];

const toggleSubMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  const button = event.currentTarget;
  const subMenu = button.nextElementSibling as HTMLElement;

  // Toggle the hidden class and the background color of the button
  if (subMenu.classList.contains('hidden')) {
    subMenu.classList.remove('hidden');
    button.classList.add('bg-secondary-2');
  } else {
    subMenu.classList.add('hidden');
    button.classList.remove('bg-secondary-2');
  }
};

const navListMobile = (
  <ul id='MainMenuList' className='mx-4 flex flex-col lg:mb-0 lg:mt-0 lg:flex-row lg:items-center'>
    {/* Menu Se Former */}
    <li className='relative'>
      <Button
        variant='text'
        ripple={false}
        className='text-white rounded-b-none flex items-center justify-start hover:bg-secondary-2 active:text-primary w-full px-4 py-2 text-base poppins-semibold'
        style={{ textTransform: 'none' }}
        onClick={(e) => toggleSubMenu(e)}>
        Se Former
        <svg width='22' height='22' viewBox='0 0 24 24' fill='white' className='ml-2' xmlns='http://www.w3.org/2000/svg'>
          <polygon points='12,15 7,9 17,9' />
        </svg>
      </Button>
      <div className='relative sub-menu absolute left-0 w-full bg-secondary-1 hidden'>
        {seformer.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className='block rounded rounded-b-none px-4 text-white text-xs text-[0.8rem] poppins-bold active:text-primary hover:bg-secondary-2 p-3'>
            {item.name}
          </a>
        ))}
        <a
          href='https://compagnons-du-devoir.com/se-former/formations-courtes/'
          className='text-white rounded flex items-center justify-start bg-orange-500 hover:bg-secondary-2 active:text-primary w-full px-4 py-2.5 text-white text-[0.8rem] poppins-bold'
          style={{ textTransform: 'none' }}>
          Formations en alternance
        </a>
        <a
          href='https://compagnons-du-devoir.com/se-former/formations-courtes/'
          className='mt-2.5 text-white rounded flex items-center justify-start bg-orange-500 hover:bg-secondary-2 active:text-primary w-full px-4 py-2.5 text-white text-[0.8rem] poppins-bold'
          style={{ textTransform: 'none' }}>
          Formations courtes
        </a>
      </div>
    </li>

    {/* Menu Métiers */}
    <li className='relative'>
      <Button
        variant='text'
        ripple={false}
        className='text-white flex items-center justify-start hover:bg-secondary-2 active:text-primary w-full px-4 py-2 text-base poppins-semibold'
        style={{ textTransform: 'none' }}
        onClick={(e) => toggleSubMenu(e)}>
        Métiers
        <svg width='22' height='22' viewBox='0 0 24 24' fill='white' className='ml-2' xmlns='http://www.w3.org/2000/svg'>
          <polygon points='12,15 7,9 17,9' />
        </svg>
      </Button>
      <div className='relative sub-menu absolute left-0 w-full bg-secondary-1 hidden'>
        {metiers.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className='block rounded rounded-b-none px-4 text-white text-xs text-[0.8rem] poppins-bold active:text-primary hover:bg-secondary-2 p-3'>
            {item.name}
          </a>
        ))}
      </div>
    </li>
    {/* Menu Se Rencontrer */}
    <li className='relative'>
      <Button
        variant='text'
        ripple={false}
        className='text-white flex items-center justify-start hover:bg-secondary-2 active:text-primary w-full px-4 py-2 text-base poppins-semibold'
        style={{ textTransform: 'none' }}
        onClick={(e) => toggleSubMenu(e)}>
        Se rencontrer
        <svg width='22' height='22' viewBox='0 0 24 24' fill='white' className='ml-2' xmlns='http://www.w3.org/2000/svg'>
          <polygon points='12,15 7,9 17,9' />
        </svg>
      </Button>
      <div className='relative sub-menu absolute left-0 w-full bg-secondary-1 hidden'>
        {serencontrer.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className='block rounded rounded-b-none px-4 text-white text-xs text-[0.8rem] poppins-bold active:text-primary hover:bg-secondary-2 p-3'>
            {item.name}
          </a>
        ))}
      </div>
    </li>
    {/* Menu Se connecter */}
    <li className='relative'>
      <Link
        to='/connexion'
        className='text-white rounded-t-none flex items-center justify-start hover:bg-secondary-2 active:text-primary w-full px-4 py-2 text-base poppins-semibold'
        style={{ textTransform: 'none' }}>
        Se connecter
      </Link>
    </li>

    {/* ------------------------ 
        Others */}
    {/* Association */}
    <li className='relative mt-10'>
      <Button
        variant='text'
        ripple={false}
        className='text-white flex items-center justify-start hover:bg-secondary-2 active:text-primary w-full px-4 py-1.5 text-[0.8rem] poppins-semibold'
        style={{ textTransform: 'none' }}
        onClick={(e) => toggleSubMenu(e)}>
        L'association
        <ChevronDownIcon className='text-white ml-2 h-3 w-3' aria-hidden='true' strokeWidth={2.5} />
      </Button>
      <div className='relative sub-menu absolute left-0 w-full bg-secondary-1 hidden'>
        {solutions.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className='block rounded rounded-b-none px-4 text-white text-[0.8rem] poppins-semibold active:text-primary hover:bg-secondary-2 p-1.5'>
            {item.name}
          </a>
        ))}
      </div>
    </li>
    {/* MaisonS et CFA */}
    <li className='relative'>
      <a
        href='https://compagnons-du-devoir.com/nos-maisons-et-cfa/'
        className='text-white flex items-center justify-start hover:bg-secondary-2 active:text-primary w-full px-4 py-1.5 text-[0.8rem] poppins-semibold'
        style={{ textTransform: 'none' }}>
        Maisons et CFA
      </a>
    </li>
    {/* Nous Soutenir */}
    <li className='relative'>
      <Button
        variant='text'
        ripple={false}
        className='text-white flex items-center justify-start hover:bg-secondary-2 active:text-primary w-full px-4 py-1.5 text-[0.8rem] poppins-semibold'
        style={{ textTransform: 'none' }}
        onClick={(e) => toggleSubMenu(e)}>
        Nous soutenir
        <ChevronDownIcon className='text-white ml-2 h-3 w-3' aria-hidden='true' strokeWidth={2.5} />
      </Button>
      <div className='relative sub-menu absolute left-0 w-full bg-secondary-1 hidden'>
        {noussoutenir.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className='block rounded rounded-b-none px-4 text-white text-[0.8rem] poppins-semibold active:text-primary hover:bg-secondary-2 p-1.5'>
            {item.name}
          </a>
        ))}
      </div>
    </li>
    {/* Espace Entreprise */}
    <li className='relative'>
      <Button
        variant='text'
        ripple={false}
        className='text-white flex items-center justify-start hover:bg-secondary-2 active:text-primary w-full px-4 py-1.5 text-[0.8rem] poppins-semibold'
        style={{ textTransform: 'none' }}
        onClick={(e) => toggleSubMenu(e)}>
        Espace entreprise
        <ChevronDownIcon className='text-white ml-2 h-3 w-3' aria-hidden='true' strokeWidth={2.5} />
      </Button>
      <div className='relative sub-menu absolute left-0 w-full bg-secondary-1 hidden'>
        {espaceentreprises.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className='block rounded rounded-b-none px-4 text-white text-[0.8rem] poppins-semibold active:text-primary hover:bg-secondary-2 p-1.5'>
            {item.name}
          </a>
        ))}
      </div>
    </li>
    {/* Espace compagnons */}
    <li className='relative'>
      <Button
        variant='text'
        ripple={false}
        className='text-white flex items-center justify-start hover:bg-secondary-2 active:text-primary w-full px-4 py-1.5 text-[0.8rem] poppins-semibold'
        style={{ textTransform: 'none' }}
        onClick={(e) => toggleSubMenu(e)}>
        Espace compagnons
        <ChevronDownIcon className='text-white ml-2 h-3 w-3' aria-hidden='true' strokeWidth={2.5} />
      </Button>
      <div className='relative sub-menu absolute left-0 w-full bg-secondary-1 hidden'>
        {espacecompagnons.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className='block rounded rounded-b-none px-4 text-white text-[0.8rem] poppins-semibold active:text-primary hover:bg-secondary-2 p-1.5'>
            {item.name}
          </a>
        ))}
      </div>
    </li>
    {/* Nous contacter */}
    <li className='relative mb-7'>
      <a
        href='https://www.compagnons-du-devoir.com/nous-contacter'
        className='text-white flex items-center justify-start hover:bg-secondary-2 active:text-primary w-full px-4 py-1.5 text-[0.8rem] poppins-semibold'
        style={{ textTransform: 'none' }}>
        Nous contacter
      </a>
    </li>
  </ul>
);

const navList = (
  <ul className='mb-4 md:mb-1 mt-2 flex lg:mb-0 lg:mt-0 flex-row md:items-center justify-center gap-4'>
    <Typography as='li' variant='small' color='white'>
      <Menu allowHover={true}>
        <MenuHandler>
          <Button
            variant='text'
            ripple={false}
            className='poppins-medium tracking-widest hover:outline-none hover:border-none outline-none px-2 md:-pl-0 lg:pl-2 text-white text-xs md:text-md xl:text-[17px] flex items-center hover:bg-transparent active:bg-transparent hover:text-primary'>
            Se former
            <ChevronDownIcon className='lg:ml-2 h-4 w-4 group-hover:text-primary' aria-hidden='true' strokeWidth={4} />
          </Button>
        </MenuHandler>
        <MenuList className='border-none flex flex-row gap-5 p-11 w-full max-w-[900px]'>
          <div className='basis-3/5 !outline-none'>
            {seformer.map((item) => (
              <MenuItem key={item.name} className='hover:bg-transparent'>
                <a href={item.href} className='-m-3 pl-0 py-2 flex items-start rounded-none p-3 text-secondary-2 hover:text-primary'>
                  <p className='poppins-semibold tracking-wide text-[18px]'>{item.name}</p>
                </a>
              </MenuItem>
            ))}
            <a
              href='https://compagnons-du-devoir.com/formations-en-alternance/'
              className='poppins-medium text-[17px] tracking-widest flex flex-row items-center justify-between my-4 mr-2 py-1 px-4 text-white bg-primary rounded-full hover:bg-secondary-1'>
              Les formations en alternance
              <ArrowRightIcon className='pl-3 w-10 h-10' />
            </a>
            <a
              href='https://compagnons-du-devoir.com/se-former/formations-courtes/'
              className='poppins-medium text-[17px] tracking-widest flex flex-row items-center justify-between my-4 py-1 px-4 text-white bg-primary rounded-full hover:bg-secondary-1'>
              Les formations courtes
              <ArrowRightIcon className='pl-3 w-10 h-10' />
            </a>
          </div>
          <div className='basis-2/5 pl-5 border-l border-gray-300 hover:outline-none'>
            <img src='/assets/menu-se-former-compagnons-768x512.webp' className='rounded-lg' />
          </div>
        </MenuList>
      </Menu>
    </Typography>
    <Typography as='li' variant='small' color='white'>
      <Menu allowHover={true}>
        <MenuHandler>
          <Button
            variant='text'
            ripple={false}
            className='poppins-medium tracking-widest hover:outline-none hover:border-none outline-none px-2 md:-pl-0 lg:pl-2 text-white text-xs md:text-md xl:text-[17px] flex items-center hover:bg-transparent active:bg-transparent hover:text-primary'>
            Métiers
            <ChevronDownIcon className='lg:ml-2 h-4 w-4 group-hover:text-primary' aria-hidden='true' strokeWidth={4} />
          </Button>
        </MenuHandler>
        <MenuList className='border-none flex flex-row gap-5 p-11 w-full max-w-[900px]'>
          <div className='basis-3/5 !outline-none'>
            {metiers.map((item) => (
              <MenuItem key={item.name} className='hover:bg-transparent'>
                <a href={item.href} className='-m-3 pl-0 py-2 flex items-start rounded-none p-3 text-secondary-2 hover:text-primary'>
                  <p className='poppins-semibold tracking-wide text-[18px]'>{item.name}</p>
                </a>
              </MenuItem>
            ))}
            <a
              href='https://compagnons-du-devoir.com/les-metiers/'
              className='poppins-medium text-[17px] tracking-widest flex flex-row items-center justify-between my-4 mr-2 py-1 px-4 text-white bg-primary rounded-full hover:bg-secondary-1'>
              Découvrir tous les métiers
              <ArrowRightIcon className='pl-3 w-10 h-10' />
            </a>
          </div>
          <div className='basis-2/5 pl-5 border-l border-gray-300 hover:outline-none'>
            <img src='/assets/menu-les-metiers-compagnons-768x512.webp' className='rounded-lg' />
          </div>
        </MenuList>
      </Menu>
    </Typography>
    <Typography as='li' variant='small' color='white'>
      <Menu allowHover={true}>
        <MenuHandler>
          <Button
            variant='text'
            ripple={false}
            className='poppins-medium tracking-widest hover:outline-none hover:border-none outline-none px-2 md:-pl-0 lg:pl-2 text-white text-xs md:text-md xl:text-[17px] flex items-center hover:bg-transparent active:bg-transparent hover:text-primary'>
            Se rencontrer
            <ChevronDownIcon className='lg:ml-2 h-4 w-4 group-hover:text-primary' aria-hidden='true' strokeWidth={4} />
          </Button>
        </MenuHandler>
        <MenuList className='border-none flex flex-row gap-5 p-11 w-full max-w-[900px]'>
          <div className='basis-3/5 !outline-none'>
            {serencontrer.map((item) => (
              <MenuItem key={item.name} className='hover:bg-transparent'>
                <a href={item.href} className='-m-3 pl-0 py-2 flex items-start rounded-none p-3 text-secondary-2 hover:text-primary'>
                  <p className='poppins-semibold tracking-wide text-[18px]'>{item.name}</p>
                </a>
              </MenuItem>
            ))}
            <a
              href='https://compagnons-du-devoir.com/se-rencontrer/'
              className='poppins-medium text-[17px] tracking-widest flex flex-row items-center justify-between my-4 mr-2 py-1 px-4 text-white bg-primary rounded-full hover:bg-secondary-1'>
              Tous les événements
              <ArrowRightIcon className='pl-3 w-10 h-10' />
            </a>
          </div>

          <div className='basis-2/5 pl-5 border-l border-gray-300 hover:outline-none'>
            <img src='/assets/menu-se-rencontrer-compagnons-768x512.webp' className='rounded-lg' />
          </div>
        </MenuList>
      </Menu>
    </Typography>
  </ul>
);
/**
 * Ce composant affiche le menu principal côté Front.
 */
export const MainMenu: FunctionComponent<{ className?: string }> = () => {
  const { user } = useUser();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 960) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'auto';
  }, [isMobileMenuOpen]);

  return (
    <div className='w-full flex flex-col lg:flex-row items-center justify-between text-white'>
      {/* Logo visible uniquement sur Desktop */}
      <Link to='/' className='hidden lg:flex -m-1.5 p-1.5'>
        <img className='h-20 md:h-32 xl:h-40 mx-auto' src='/assets/aocdtf-logo-white-orange.svg' alt='Compagnons du devoir et du Tour de France' />
      </Link>

      {/* Bouton du menu mobile */}
      <div className='lg:hidden flex justify-between items-center w-full p-4'>
        <Link to='/' className='lg:hidden'>
          <img className='h-16 transform scale-150 mx-5' src='/assets/aocdtf-logo-white-orange.svg' alt='Logo' />
        </Link>
        <button
          onClick={toggleMobileMenu}
          className='text-white p-2 focus:outline-none transform scale-150' // Ajoutez transform scale-150 pour le bouton
        >
          {isMobileMenuOpen ? (
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='size-6'>
              <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
            </svg>
          ) : (
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={2.5} stroke='currentColor' className='size-6'>
              <path strokeLinecap='round' strokeLinejoin='round' d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5' />
            </svg>
          )}
        </button>
      </div>

      {/* Menu Navigation Mobile */}
      {isMobileMenuOpen &&
        ReactDOM.createPortal(
          <div className='fixed top-0 left-0 w-full h-full bg-secondary-1 z-50 overflow-y-auto'>
            <button className='absolute top-2.5 right-2.5  text-white p-2' onClick={toggleMobileMenu}>
              <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={2.5} stroke='currentColor' className='size-6'>
                <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
              </svg>
            </button>
            <Link to='/'>
              <img className='mx-8 my-4' src='/assets/aocdtf-logo-white-orange.svg' alt='Logo' style={{ width: '118px' }} />
            </Link>

            <button
              className='mx-8 my-4 bg-orange-500 text-white text-base poppins-bold py-1.5 px-8 rounded-full shadow-md hover:bg-secondary-1 border border-secondary-1 hover:border-white focus:outline-none'
              style={{ textTransform: 'none' }}>
              <span className='inline-flex items-center pt-1'>
                <ArrowRightIcon className='text-white h-5' strokeWidth={2.5} aria-hidden='true' />
                Je m'inscris
              </span>
            </button>
            {navListMobile}
            <a
              href='' /* Pas de lien existant */
              className='relative mx-8 my-6 border border-white text-white text-[0.8rem] poppins-medium py-1.5 px-5 rounded-full hover:bg-primary hover:border-primary transition-colors duration-300'
              style={{ textTransform: 'none' }}>
              Je fais un don
            </a>
          </div>,
          document.body,
        )}

      {/* Menu Navigation for Desktop */}
      <div className='hidden lg:flex flex-col lg:flex-row items-center justify-center lg:justify-end w-full'>
        <div className='flex flex-col lg:flex-row items-center'>
          {navList}
          <div className='flex flex-row items-center'>
            <Link
              to='/connexion'
              className='poppins-bold tracking-widest inline-block md:mr-4 lg:mr-0 mb-4 md:mb-0 text-center px-5 md:px-2 lg:px-3 pt-0.5 pb-0.5 text-xs md:text-md xl:text-[17px] leading-6 text-white hover:text-primary uppercase'>
              {user && user.token ? 'Espace client' : 'Se connecter'}
            </Link>
            {!(user && user.token) && (
              <Link
                to='/inscription'
                className='poppins-medium inline-block rounded-full px-2 lg:px-7 pt-0.5 pb-0.5 text-xs md:text-md xl:text-lg leading-6 text-center lg:ml-2 mb-5 md:mb-0 text-white shadow-sm ring-1 ring-white bg-transparent hover:ring-primary hover:bg-primary hover:text-white'>
                Je m'inscris
                <ArrowRightIcon className='w-9 h-9 inline pl-3' />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
