import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { BanniereVitrine } from '../components/BanniereVitrine';
import { Container } from '../components/Container';
import { FilDariane } from '../components/FilDariane';
import { LoadingAbsolute } from '../components/LoadingAbsolute';
import { MainMenu } from '../components/MainMenu';
import { Config } from '../config/config';
import { useApi } from '../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../types/api';
import { Filiere } from '../types/visitorRessources';
import { Metier } from '../types/visitorRessources';
import nl2br from 'react-nl2br';
import { ArrowRightCircleIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon, CubeTransparentIcon } from '@heroicons/react/24/outline';
import { handleError } from '../utils/ErrorHandler';

/**
 * Affiche la fiche filière.
 */
export const FicheFiliereScreen: FunctionComponent = () => {
  /**
   * On a besoin des paramètres pour avoir le slug.
   */
  const params = useParams();

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * État local pour sauvegarder la filière.
   */
  const [filiere, setFiliere] = useState<Filiere | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Etat pour stocker l'information sur la disponibilité des formations par métier
   */
  const [metiersAvecFormations, setMetiersAvecFormations] = useState<{ [key: string]: boolean }>({});

  /**
   * Permet de recharger les données au chargement de page ou au changement de paramètres
   */
  useEffect(() => {
    handleShow();
  }, [client, params]);

  /**
   * Fonction qui vérifie si des formations existent pour chque métier et mettre à jour l'état.
   * */
  const fetchFormationsAvailability = (metiers: Metier[]) => {
    const availability: { [key: string]: boolean } = {};
    metiers.forEach((metier: Metier) => {
      if (metier.formations && Array.isArray(metier.formations)) {
        availability[metier.slug] = metier.formations.length > 0;
      } else {
        availability[metier.slug] = false;
      }
    });
    setMetiersAvecFormations(availability);
  };

  /**
   * Cette méthode récupère la filière qui possède le slug fournit et la stocke dans un état local
   */
  const handleShow = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Filiere>>(`/filieres/${params.slug}`)
      .then(async (response) => {
        const filiereData = response.data.data;
        setFiliere(filiereData);
        const metiersAvecDetails = await Promise.all(
          filiereData.metiers.map(async (metierRef) => {
            const responseMetier = await client.get<GoodApiResponse<Metier>>(`/metiers/${metierRef.slug}`);
            return responseMetier.data.data;
          }),
        );

        fetchFormationsAvailability(metiersAvecDetails);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  if (filiere === null) {
    return <Fragment>Chargement...{apiIsLoading ? <LoadingAbsolute /> : ''}</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Fiche filière - {Config.app_label}</title>
      </Helmet>
      <BanniereVitrine>
        <div className='absolute top-0 right-0 left-0 bottom-0 w-full h-full bg-secondary-2/70'></div>
        <div className='relative flex flex-col items-center h-full mx-auto'>
          <MainMenu className='relative pb-20' />
        </div>
        <div className='lg:max-w-xl xl:max-w-7xl w-full relative h-full 2xl:px-8 mt-20 lg:mt-[120px] text-center md:text-left mr-auto xl:mx-auto'>
          <h1 className='text-white text-3xl w-full uppercase poppins-semibold xl:pr-96'>{filiere.nom}</h1>
          <FilDariane
            className='bg-transparent w-full text-white pl-0 !mb-2 mt-2 xl:pr-96'
            items={[{ label: filiere.nom, link: `/filieres/${filiere.slug}` }]}
          />
        </div>
      </BanniereVitrine>
      <Container className='pb-11 bg-gray-100 h-full'>
        <div className='md:flex md:flex-row w-full'>
          <div className='basis-1/1 md:basis-3/5 lg:basis-2/3 h-full pt-11'>
            <div className='md:mr-4 mb-11'>
              <p className='text-gray-700 poppins'>{nl2br(filiere.description)}</p>
              <h2 className='text-2xl text-secondary-1 mt-7 poppins-semibold'>Découvrez les métiers de la même filière</h2>
              <ul className='mt-4'>
                {filiere.metiers.map((metier, index) => (
                  <li key={index}>
                    {metiersAvecFormations[metier.slug] ? (
                      <Link to={`/metiers/${metier.slug}`} className='hover:text-primary flex flex-row gap-2 items-center text-gray-700 poppins-semibold'>
                        <ArrowRightCircleIcon className='w-4 h-4 text-primary' />
                        <span>{metier.nom}</span>
                      </Link>
                    ) : (
                      <span className='flex flex-row gap-2 items-center text-gray-400 poppins'>
                        <ArrowRightCircleIcon className='w-4 h-4 text-gray-400' />
                        <span>{metier.nom}</span>
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='relative basis-1/1 md:basis-2/5 lg:basis-1/3 md:pl-11 md:-mt-11'>
            <div className='bg-white rounded-lg shadow-md pb-7 pt-7 px-7 mb-7 lg:ml-4'>
              <div className='h-44 overflow-hidden shadow-md rounded-lg'>
                {filiere.image_couverture ? (
                  <img alt={filiere.nom} src={filiere.image_couverture as string} tabIndex={0} className='object-cover w-full h-full' loading='lazy' />
                ) : (
                  <div className={`rounded-lg shadow-md object-cover w-full h-full bg-gray-700 flex items-center justify-center`}>
                    <CubeTransparentIcon className='h-11 w-11 text-white/50' />
                  </div>
                )}
              </div>
              <p className='my-7 text-sm poppins'>
                Pour consulter les sessions disponibles dans le centre de votre choix, veuillez effectuer une recherche en cliquant sur le bouton ci-dessous.
              </p>
              <Link
                to='/'
                className='flex flex-row items-center text-center text-white py-1 px-5 bg-primary hover:bg-secondary-1 rounded-full shadow-primary/50 hover:shadow-secondary-1/50 uppercase text-sm poppins-semibold'>
                Rechercher une formation
                <ArrowRightIcon className='pl-3 w-9 h-9' />
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};
