import {
  ArrowDownTrayIcon,
  ShareIcon,
  ClockIcon,
  AcademicCapIcon,
  MapPinIcon,
  HeartIcon as HeartEmptyIcon,
  CheckCircleIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import { ChevronLeftIcon, ChevronRightIcon, CurrencyEuroIcon, DocumentIcon, UserGroupIcon } from '@heroicons/react/20/solid';

import { Fragment, FunctionComponent, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { Accordeon } from '../components/Accordeon';
import { Container } from '../components/Container';
import { FilDariane } from '../components/FilDariane';
import { LoadingAbsolute } from '../components/LoadingAbsolute';
import { MainMenu } from '../components/MainMenu';
import { Config } from '../config/config';
import { useApi } from '../providers/ApiProvider';
import { Avis, Formation, FormationSimilaire } from '../types/visitorRessources';
import { ErrorApiResponse } from '../types/api';
import { HeartIcon as HeartFullIcon } from '@heroicons/react/20/solid';
import { TypeProfilEnum } from '../types/auth';
import { useUser } from '../providers/UserProvider';
import nl2br from 'react-nl2br';
import { ProgressBar } from '../components/ProgressBar';
import { StarGroupDisplay } from '../components/Stars/StarGroupDisplay';

import { handleError } from '../utils/ErrorHandler';
import { CtaFormationNonTrouvee } from '../components/CtaFormationNonTrouvee';
import { CtaHomeEspaceClient } from '../components/CtaHomeEspaceClient';
import { CtaHomeFSM } from '../components/CtaHomeFSM';
import { CtaHomeHandi } from '../components/CtaHomeHandi';
import { ModaleEspaceClient } from '../components/Modales/Front/ModaleEspaceclient';
import { ModaleChoixSession } from '../components/Modales/Front/ModaleChoixSession';


/**
 * Affiche la fiche formation.
 */
export const FicheFormationScreen: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci.
   */
  const { user, profil } = useUser();

  /**
   * On a besoin des paramètres pour avoir le slug.
   */
  const params = useParams();

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * État local qui stocke la formation.
   */
  const [formation, setFormation] = useState<Formation | null>(null);

  /**
   * État local qui stocke si la formation est non trouvée.
   */
  const [formationNotFound, setFormationNotFound] = useState<boolean>(false);

  /**
   * État local qui contient la liste des formations similaires.
   */
  const [formationsSimilaires, setFormationsSimilaires] = useState<FormationSimilaire[]>([]);

  /**
   * État local qui gère la visibilité des icônes de partage.
   */
  const [isShareIconsVisible, setIsShareIconsVisible] = useState<boolean>(false);

  /**
   * État local qui gère l'apparition de la modale de connexion lorsque l'on clique sur favori.
   */
  const [modaleEspaceClientEstVisible, setModaleEspaceClientEstVisible] = useState<boolean>(false);

  /**
   * État local qui gère l'apparition de la modale de connexion lorsque l'on clique sur favori.
   */
  const [modaleChoixSessionEstVisible, setModaleChoixSessionEstVisible] = useState<boolean>(false);

  /**
   * État local qui gère la note moyenne des avis.
   */
  const [avisNoteMoyenne, setAvisNoteMoyenne] = useState<Avis['note'] | null>(null);

  /**
   * On stocke la description SEO pour le SEO.
   */
  const [descriptionCourte, setDescriptionCourte] = useState<Formation['description_courte']>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * On a besoin de l'URL pour accéder directement aux ancres.
   */
  const location = useLocation();

  /**
   * État local qui permet de stocker l'état de chargement de la page.
   */
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  /**
   * État local qui permet de stocker l'état de visibilité du formulaire de contact.
   */
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  const ContactFormRef = useRef<HTMLDivElement>(null); // reférence pour scroll
  /**
   * Fonction handler pour le click sur le bouton 'Contacter'
   */
  const handleButtonContactClick = () => {
    setShowContactForm(!showContactForm); // Toggle the display of the contact form
    setTimeout(() => {
      // Scroll to the contact form
      ContactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100); // Timeout to ensure the form is visible before scrolling
  };

  /**
   * Test si l'URL contient une ancre. Si oui, on déroule jusqu'a elles.
   */
  useEffect(() => {
    if (location.hash !== null && location.hash !== '') {
      if (location.hash.match(/#ancre-sessions$/)) {
        handleVillesEtDates();
      }
      if (location.hash.match(/#ancre-avis$/)) {
        setTimeout(() => {
          (document.querySelector('#ancre-avis') as HTMLElement).scrollIntoView({
            behavior: 'smooth',
          });
        }, 1000);
      }
    }
  }, [isLoaded]);

  /**
   * Cet effet récupère les informations de la formation, les avis liés ainsi que les formations similaires au chargement du composant
   */
  useEffect(() => {
    handleShow();
  }, [client, params]);

  /**
   * Cette méthode récupère les informations de la formation, les avis liés ainsi que les formations similaires.
   */
  const handleShow = () => {
    setApiIsLoading(true);
    setFormationNotFound(false);

    client
      .get<{
        data: Formation;
        similaires: FormationSimilaire[];
      }>(`/formations/${params.slug}`)
      .then((response) => {
        setFormation(response.data.data as unknown as Formation);
        setFormationsSimilaires(response.data.similaires); // Le 'any' est volontaire, car dans se cas précis, on a des formations similaires à celle que l'on souhaite.
        setAvisNoteMoyenne(
          (response.data.data as unknown as Formation).avis.length
            ? (response.data.data as unknown as Formation).avis
                .map((avi) => avi.note)
                .reduce((accumulateur, valeurCourante) => accumulateur + valeurCourante, 0) / (response.data.data as unknown as Formation).avis.length
            : null,
        );
        setDescriptionCourte(response.data.data.description_courte);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
        setFormationNotFound(true);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Permet d'ajouter ou retirer une formation de la liste des favoris une formation en fonction de si elle se trouve déjà dans le liste ou pas.
   */
  const handleFavorite = async () => {
    if (user === null || !user.roles.includes('standard')) {
      handleModaleEspaceClient();
    } else {
      setApiIsLoading(true);

      const url = `/${
        profil !== null && (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue) ? 'entreprise' : 'salarie'
      }/favoris/${formation?.slug}`;

      try {
        if (formation?.est_favori) {
          await client.delete(url);
          toast.success(`Formation retirée des favoris`);
        } else {
          await client.post(url);
          toast.success(`Formation ajoutée aux favoris`);
        }
        setFormation({ ...formation, est_favori: !formation?.est_favori } as Formation);
      } catch (error: any) {
        handleError(error);
      }
      setApiIsLoading(false);
    }
  };

  const handleVillesEtDates = async () => {
    if (user === null || !user.roles.includes('standard')) {
      handleModaleEspaceClient();
    } else {
      setModaleChoixSessionEstVisible(true);
    }
  };

  /**
   * Cette méthode affiche une modale de connexion lorsque l'on clique sur 'Ajouter aux favoris'.
   */
  const handleModaleEspaceClient = () => {
    setModaleEspaceClientEstVisible(true);
  };

  const [avisShown, setAvisShown] = useState(0);

  const handleAvisShown = (value: number) => {
    setAvisShown(value);
  };

  if (formation === null && formationNotFound === false) {
    return <Fragment>Chargement...{apiIsLoading ? <LoadingAbsolute /> : ''}</Fragment>;
  }

  if (formation === null && formationNotFound === true) {
    return <CtaFormationNonTrouvee />;
  }

  if (formation !== null) {
    return (
      <Fragment>
        <Helmet>
          <title>
            {formation.nom} - {Config.app_label}
          </title>
          {descriptionCourte && <meta name='description' content={descriptionCourte} />}
        </Helmet>
        <div
          className='bg-cover bg-center h-auto text-white py-3 px-4 lg:px-8 xl:px-11 object-fill relative'
          style={{
            backgroundImage: formation.image_couverture ? `url("${formation.image_couverture}")` : `url("/assets/header-fiche-formations.webp")`,
          }}>
          <div className='absolute top-0 right-0 left-0 bottom-0 w-full h-full bg-secondary-2/60'></div>
          <div className='relative flex flex-col items-center h-full mx-auto'>
            <MainMenu className='relative pb-20' />
            <div className='lg:max-w-xl xl:max-w-7xl w-full relative h-full 2xl:px-8 mt-20 lg:mt-[120px] text-center md:text-left mr-auto xl:mx-auto'>
              <h1 className='text-white text-2xl w-full uppercase poppins-semibold xl:pr-96'>{formation.nom}</h1>
              <FilDariane
                className={
                  formation.est_certifiant === true || formation.est_cpf === true || formation.est_riseup === true
                    ? 'bg-transparent w-full text-white pl-0 !mb-7 mt-2 xl:pr-96'
                    : 'bg-transparent w-full text-white pl-0 !mb-2 mt-2 xl:pr-96'
                }
                items={[{ label: `Formation ${formation.nom}`, link: `/formations/${formation.slug}` }]}
              />
            </div>
          </div>
        </div>
        <Container className='bg-[#F7FAFD] h-full pb-7'>
          <div className='flex flex-wrap w-full'>
            <div className='w-full basis-1/1 lg:basis-2/3 h-full'>
              {(formation.est_certifiant === true || formation.est_cpf === true || formation.est_riseup === true) && (
                <div className='flex flex-row items-center w-full gap-5 -mt-4'>
                  {formation.est_certifiant && (
                    <div className='bg-primary text-white text-sm montserrat-semibold rounded-md px-4 py-2'>Formation certifiante</div>
                  )}
                </div>
              )}
              <div className='h-4'></div>
              {formation.code_repertoire_specifique && formation.code_repertoire_specifique.length > 0 && (
                <div className='mb-4 inline-flex gap-2 items-center'>
                  <DocumentIcon className='text-black w-5 h-5' />
                  <div className='text-sm text-gray-600'>
                    RS&nbsp;:&nbsp;
                    {formation.code_repertoire_specifique}
                  </div>
                </div>
              )}
              {formation.description && formation.description.length > 0 && (
                <div className='mb-6 text-gray-600 text-sm leading-relaxed'>{formation.description}</div>
              )}
              {formation.texte_prerequis && formation.texte_prerequis.length > 0 && (
                <Fragment>
                  <h4 className='text-secondary-1 text-lg mb-1 leading-normal poppins-semibold'>Pré-requis pour cette formation&nbsp;:</h4>
                  <div className='mb-8 text-gray-600 text-sm'>{formation.texte_prerequis}</div>
                </Fragment>
              )}
              <div className='mb-6'>
                <Accordeon
                  objectifs={formation.texte_objectifs}
                  contenu={formation.texte_contenu}
                  methode={formation.texte_methodologie}
                  evaluation={formation.texte_modalites_evaluation}
                  modules={formation.modules}
                  acces={formation.texte_modalites_access}
                  accessibilite={formation.texte_accessibilite}
                  similaires={formationsSimilaires}
                />
              </div>
            </div>

            <div className='basis-1/1 lg:basis-1/3 relative lg:pl-7 xl:pl-11 mt-6 lg:-mt-32 w-full'>
              <div className='bg-white rounded-lg shadow-md pt-4 px-4 mb-7 pb-8'>
                <div className='flex flex-row gap-4 items center justify-start mb-2'>
                  <span
                    className='cursor-pointer'
                    onClick={() => {
                      handleFavorite();
                    }}>
                    {formation.est_favori ? (
                      <HeartFullIcon className='h-5 w-5 text-secondary-1 hover:text-primary' title='Retirer des favoris' />
                    ) : (
                      <HeartEmptyIcon className='h-5 w-5 text-secondary-1 hover:text-primary' title='Ajouter aux favoris' />
                    )}
                  </span>

                  <span
                    className='cursor-pointer'
                    title='Partager'
                    onClick={() => {
                      setIsShareIconsVisible(!isShareIconsVisible);
                    }}>
                    <ShareIcon className='h-5 w-5 text-secondary-1 hover:text-primary' />
                  </span>

                  <span
                    title='Télécharger en version PDF'
                    className='cursor-pointer'
                    onClick={() => {
                      if (user === null || !user.roles.includes('standard')) {
                        handleModaleEspaceClient();
                      } else {
                        client.fetchPdf(formation.url_pdf);
                      }
                    }}>
                    <ArrowDownTrayIcon className='h-5 w-5 text-secondary-1 hover:text-primary' />
                  </span>
                </div>

                {isShareIconsVisible && (
                  <div className='flex flex-row gap-2'>
                    <div>
                      <FacebookShareButton className='mr-2' url={window.location.href}>
                        <FacebookIcon size={32} round={true} />
                      </FacebookShareButton>
                      <LinkedinShareButton
                        className='mr-2'
                        url={window.location.href}
                        title={`Recommandation formation : ${formation.nom}`}
                        source={window.location.href}>
                        <LinkedinIcon size={32} round={true} />
                      </LinkedinShareButton>
                      {formation.image_couverture && (
                        <PinterestShareButton className='mr-2' url={window.location.href} media={formation.image_couverture}>
                          <PinterestIcon size={32} round={true} />
                        </PinterestShareButton>
                      )}
                      <TwitterShareButton className='mr-2' url={window.location.href} title={`Recommandation formation : ${formation.nom}`}>
                        <TwitterIcon size={32} round={true} />
                      </TwitterShareButton>
                      <EmailShareButton url={window.location.href} subject={`Recommandation formation : ${formation.nom}`}>
                        <EmailIcon size={32} round={true} />
                      </EmailShareButton>
                    </div>
                    <div
                      className='rounded-full aspect-1 h-8 w-8 bg-gray-700 flex items-center justify-center cursor-pointer'
                      title='Copier le lien de la formation'
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        toast.success('Lien de la fiche formation copié !');
                      }}>
                      <DocumentDuplicateIcon className='h-4 w-4 text-white' />
                    </div>
                  </div>
                )}

                <div className='h-48 mb-2 overflow-hidden'>
                  {formation.image_couverture ? (
                    <img
                      alt={formation.nom}
                      src={formation.image_couverture}
                      tabIndex={0}
                      className='rounded-lg shadow-md object-cover w-full h-full'
                      loading='lazy'
                    />
                  ) : (
                    <div className={`rounded-lg shadow-md object-cover w-full h-full bg-gray-700 flex items-center justify-center`}>
                      <AcademicCapIcon className='h-11 w-11 text-white/50' />
                    </div>
                  )}
                </div>

                {formation.filiere && <div className='poppins-semibold text-lg uppercase text-secondary-2'>{formation.filiere.nom}</div>}

                {avisNoteMoyenne ? (
                  <div
                    className='cursor-pointer flex flex-row items-center justify-start gap-2'
                    onClick={() => {
                      (document.querySelector('#ancre-avis') as HTMLElement).scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}>
                    <StarGroupDisplay note={avisNoteMoyenne as number} className={`w-4 h-4 text-primary`} />
                    <div className='text-sm text-gray-500'>{`- sur ${new Intl.NumberFormat('default').format(formation.avis.length)} avis`}</div>
                  </div>
                ) : (
                  <span>Pas encore d'avis</span>
                )}

                {formation.duree_heures && (
                  <div className='flex flex-row items-start justify-start gap-1 mt-3'>
                    <ClockIcon className='w-5 h-5 text-primary flex-none' />
                    <div className='grow'>
                      <div className='text-sm poppins-semibold text-secondary-2'>Durée de la formation</div>
                      <div className='text-sm text-gray-600'>{formation.duree_heures} heures</div>
                    </div>
                  </div>
                )}

                {formation.texte_public && (
                  <div className='flex flex-row items-start justify-start gap-1 mt-3'>
                    <UserGroupIcon className='w-5 h-5 text-primary flex-none' />
                    <div className='grow'>
                      <div className='text-sm poppins-semibold text-secondary-2'>Public</div>
                      <div className='text-sm text-gray-600'>{formation.texte_public}</div>
                    </div>
                  </div>
                )}

                {formation.prix_vente && (
                  <div className='flex flex-row items-start justify-start gap-1 mt-3'>
                    <CurrencyEuroIcon className='w-5 h-5 text-primary flex-none' />
                    <div className='grow'>
                      <div className='text-sm poppins-semibold text-secondary-2'>Coût de la formation</div>
                      <div className='text-sm text-gray-600'>
                        {new Intl.NumberFormat('default', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(formation.prix_vente / 100)}
                        &nbsp;TTC
                        {formation.est_cpf === true && (
                          <span className='inline-flex items-center'>
                            &nbsp;-&nbsp;
                            <CheckCircleIcon className='w-4 h-4' />
                            &nbsp;CPF
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {formation.diplome_prepare && (
                  <div className='flex flex-row items-start justify-start gap-1 mt-3'>
                    <AcademicCapIcon className='w-5 h-5 text-primary flex-none' />
                    <div className='grow'>
                      <div className='text-sm poppins-semibold text-secondary-2'>Préparation</div>
                      <div className='text-sm text-gray-600'>{formation.diplome_prepare.nom}</div>
                    </div>
                  </div>
                )}

                {formation.sites && formation.sites.length > 0 && (
                  <div className='flex flex-row items-start justify-start gap-1 mt-3'>
                    <MapPinIcon className='w-5 h-5 text-primary flex-none' />
                    <div className='grow'>
                      <div className='text-sm poppins-semibold text-secondary-2'>Dispensé à</div>
                      <div className='text-sm text-gray-600'>{new Intl.ListFormat().format(formation.sites.map((site) => site.ville as string))}</div>
                    </div>
                  </div>
                )}
                <div
                  className='rounded-full border border-transparent bg-secondary-2 hover:border-secondary-1 hover:bg-secondary-1 px-3 py-1 poppins-semibold text-white mt-4 text-center cursor-pointer'
                  onClick={handleVillesEtDates}>
                  Villes et dates
                </div>
                {profil === null && (
                  <Link
                    to='/inscription'
                    onClick={handleButtonContactClick}
                    className='block rounded-full border border-transparent bg-primary px-3 py-1 poppins-semibold text-white mt-4 text-center cursor-pointer'>
                    S'inscrire
                  </Link>
                )}
              </div>

              {avisNoteMoyenne ? (
                <div className='bg-white rounded-lg shadow-md p-4' id='ancre-avis'>
                  <h4 className='text-secondary-1 text-xl montserrat-semibold mb-1 leading-normal'>Avis</h4>
                  <div className='cursor-pointer flex flex-row items-center justify-start gap-2 mb-2'>
                    <StarGroupDisplay note={avisNoteMoyenne as number} className={`w-4 h-4 text-primary`} />
                    <div className='text-xs text-gray-500 montserrat-semibold'>{`- sur ${new Intl.NumberFormat('default').format(
                      formation.avis.length,
                    )} avis`}</div>
                  </div>
                  <div className='pb-4 text-gray-600 font-normal'>
                    <div className='flex flex-row items-center gap-2'>
                      <span className='w-[20px] flex-none text-sm'>5</span>
                      <div className='grow'>
                        <ProgressBar value={(formation.avis.filter((avi) => avi.note === 5).length * 100) / formation.avis.length} color='amber' />
                      </div>
                      <span className='w-[40px] text-sm text-center'>
                        {new Intl.NumberFormat('default', { style: 'percent' }).format(
                          formation.avis.filter((avi) => avi.note === 5).length / formation.avis.length,
                        )}
                      </span>
                    </div>
                    <div className='flex flex-row items-center gap-2'>
                      <span className='w-[20px] flex-none text-sm'>4</span>
                      <div className='grow'>
                        <ProgressBar value={(formation.avis.filter((avi) => avi.note === 4).length * 100) / formation.avis.length} color='amber' />
                      </div>
                      <span className='w-[40px] text-sm text-center'>
                        {new Intl.NumberFormat('default', { style: 'percent' }).format(
                          formation.avis.filter((avi) => avi.note === 4).length / formation.avis.length,
                        )}
                      </span>
                    </div>
                    <div className='flex flex-row items-center gap-2'>
                      <span className='w-[20px] flex-none text-sm'>3</span>
                      <div className='grow'>
                        <ProgressBar value={(formation.avis.filter((avi) => avi.note === 3).length * 100) / formation.avis.length} color='amber' />
                      </div>
                      <span className='w-[40px] text-sm text-center'>
                        {new Intl.NumberFormat('default', { style: 'percent' }).format(
                          formation.avis.filter((avi) => avi.note === 3).length / formation.avis.length,
                        )}
                      </span>
                    </div>
                    <div className='flex flex-row items-center gap-2'>
                      <span className='w-[20px] flex-none text-sm'>2</span>
                      <div className='grow'>
                        <ProgressBar value={(formation.avis.filter((avi) => avi.note === 2).length * 100) / formation.avis.length} color='amber' />
                      </div>
                      <span className='w-[40px] text-sm text-center'>
                        {new Intl.NumberFormat('default', { style: 'percent' }).format(
                          formation.avis.filter((avi) => avi.note === 2).length / formation.avis.length,
                        )}
                      </span>
                    </div>
                    <div className='flex flex-row items-center gap-2'>
                      <span className='w-[20px] flex-none text-sm'>1</span>
                      <div className='grow'>
                        <ProgressBar value={(formation.avis.filter((avi) => avi.note === 1).length * 100) / formation.avis.length} color='amber' />
                      </div>
                      <span className='w-[40px] text-sm text-center'>
                        {new Intl.NumberFormat('default', { style: 'percent' }).format(
                          formation.avis.filter((avi) => avi.note === 1).length / formation.avis.length,
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='pb-4'>
                    {formation.avis.map((avi, index) => (
                      <div key={index} className={index == avisShown ? 'block' : 'hidden'}>
                        <div className='bg-[#F6FAFD] rounded-sm shadow-md p-4 relative'>
                          <div className='flex flex-row items-center justify-center'>
                            <StarGroupDisplay note={avi.note} />
                            <span className='text-xs text-gray-600'>
                              &nbsp;-&nbsp;Publié le&nbsp;
                              {avi.date
                                ? `${new Intl.DateTimeFormat('default', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour12: false,
                                  }).format(new Date(avi.date as string))}`
                                : ''}
                            </span>
                          </div>
                          <p className='text-gray-700 mt-4 mb-2 text-xs text-center'>{nl2br(avi.description)}</p>
                          <div className='absolute -bottom-2 left-1/2 -ml-2 bg-[#F6FAFD] w-4 h-4 rotate-45'></div>
                        </div>

                        <div className='p-4 text-center'>
                          <div className='text-sm text-secondary-2 montserrat-semibold'>{avi.nom && avi.nom}</div>
                          <div className='text-xs text-gray-600'>{avi.entreprise && avi.entreprise}</div>
                        </div>
                      </div>
                    ))}

                    {formation.avis.length > 0 && (
                      <div className='flex flex-row items-center justify-center gap-2'>
                        <div className='w-6 flex flex-row items-center justify-center'>
                          {avisShown >= 1 ? (
                            <button className='rounded-full bg-secondary-2 text-white' onClick={() => handleAvisShown(avisShown - 1)}>
                              <ChevronLeftIcon className='h-4 w-4' />
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='text-sm text-gray-600'>
                          {avisShown + 1}/{formation.avis.length}
                        </div>
                        <div className='w-6 flex flex-row items-center justify-center'>
                          {avisShown + 1 < formation.avis.length ? (
                            <button className='rounded-full bg-secondary-2 text-white' onClick={() => handleAvisShown(avisShown + 1)}>
                              <ChevronRightIcon className='h-4 w-4' />
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='pb-4 text-gray-600 text-xs text-center'>Tous les avis sont des avis vérifiés.</div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </Container>
        
        <CtaHomeEspaceClient />
        <CtaHomeFSM />
        <CtaHomeHandi />
        <ModaleEspaceClient visible={modaleEspaceClientEstVisible} setVisible={setModaleEspaceClientEstVisible}></ModaleEspaceClient>
        {profil !== null && (
          <ModaleChoixSession
            visible={modaleChoixSessionEstVisible}
            setVisible={setModaleChoixSessionEstVisible}
            client={client}
            sessions={formation?.sessions}
            formation={formation}
            profil={profil}></ModaleChoixSession>
        )}
      </Fragment>
    );
  }

  return <Fragment></Fragment>;
};
