import { Link } from 'react-router-dom';
import { AcademicCapIcon, ArrowRightIcon, ClockIcon } from '@heroicons/react/24/outline';
import { Fragment, FunctionComponent } from 'react';
import { Formation } from '../types/visitorRessources';
import { HeartIcon as HeartFullIcon } from '@heroicons/react/20/solid';
import { HeartIcon as HeartEmptyIcon } from '@heroicons/react/24/outline';
/**
 * Ce composant affiche La liste des formations en permettant d'en ajouter vite fait en favoris.
 *
 * @param formations - Tableau de formations.
 * @param handleFavorite - Callback qui permet l'ajout de formation en favoris.
 */
export const SearchResultCard: FunctionComponent<{
  formations: Formation[];
  handleFavorite?: (formation: Formation) => void;
}> = ({ formations, handleFavorite }) => {
  return (
    <Fragment>
      {formations.map((formation, index) => (
        <div
          key={index}
          className='bg-white w-full focus:outline-none pb-4 hover:scale-105 transition transform shadow-sm hover:shadow-lg rounded-lg overflow-hidden'>
          <div className='relative bg-secondary-1'>
            <Link to={`/formations/${formation.slug}`}>
              <div>
                {formation.image_couverture ? (
                  <img
                    alt={formation.nom}
                    src={formation.image_couverture}
                    tabIndex={0}
                    className='focus:outline-none w-full h-44 object-cover opacity-70 hover:opacity-90'
                    loading='lazy'
                  />
                ) : (
                  <div className={`w-full h-44 object-cover bg-gray-700 flex items-center justify-center`}>
                    <AcademicCapIcon className='h-11 w-11 text-white/50' />
                  </div>
                )}
              </div>
            </Link>
          </div>
          <div>
            <div className='flex items-center justify-between px-4 pt-4'>
              <Link
                to={`/formations/${formation.slug}`}
                className={`text-primary hover:text-secondary-1 focus:outline-none text-sm poppins-semibold max-w-[280px] h-10 mb-2`}
                title={formation.nom}>
                <span className='block line-clamp-2'>{formation.nom}</span>
              </Link>
              {formation.est_favori !== undefined && (
                <div className='w-5 h-5 cursor-pointer' onClick={() => handleFavorite && handleFavorite(formation)}>
                  {formation.est_favori ? (
                    <HeartFullIcon className='h-5 w-5 text-secondary-1 hover:text-primary' title='Retirer des favoris' />
                  ) : (
                    <HeartEmptyIcon className='h-5 w-5 text-secondary-1 hover:text-primary' title='Ajouter aux favoris' strokeWidth={2.3} />
                  )}
                </div>
              )}
            </div>

            <div>
              <div className='px-4 flex flex-wrap items-center'>
                <ClockIcon className='w-7 h-7 pr-2 text-primary flex-none' />
                <div className='grow'>
                  {formation.duree_heures && <span className='text-gray-700 text-xs lg:text-md mr-2'>Durée : {formation.duree_heures} heures</span>}
                </div>
              </div>
              <Link to={`/formations/${formation.slug}`}>
                <div className='text-secondary-1 hover:text-primary px-4 flex items-center montserrat-semibold text-xs'>
                  <ArrowRightIcon className='w-7 h-7 pr-2 flex-none' strokeWidth={2} />
                  <div className='grow'>Voir la fiche détaillée</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};
